export const baseImg = "mytechreact/img"
export const homeRoute ="/"
export const ourcourseRoute ="/our-courses"
export const contactUsRoute ="/contact-us"
export const servicesRoute ="/our-services"
export const aboutUsRoute ="/about-us"
export const clientsroute ="/clients"

// company detial 

export const CompanyName ="MyTech Professionals"
export const CompanyPhone ="9891555001"
export const CompanyPhone1 ="9871771785"
export const CompanyEmail ="info@mytechpro.in"
export const CompanyWebsite ="mytechpro.in"



