
import Hero from "../components/privacypolicy/Hero";
import PricacyPolicyData from "../components/privacypolicy/PricacyPolicyData";
import React from "react";

const Privacypolicy = () => {
  
  return (
    <>
        {/* <Hero title="Privacy Policy" /> */}
        <PricacyPolicyData />
      
    </>
  );
};

export default Privacypolicy;
