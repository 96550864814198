import Hero from "../components/privacypolicy/Hero";
import TermsConditionsData from "../components/termsconditions/TermsConditionsData";
import React from "react";

const TermsConditions = () => {

  
  return (
    <div>
    
        {/* <Hero title="Terms & Conditions" /> */}
        <TermsConditionsData />
    
    </div>
  );
};

export default TermsConditions;
