import OurSevicesDetails from "../components/ourservices/OurSevicesDetails";

const OurService = () => {
  return (
    <>
    
        <OurSevicesDetails />
    </>
  );
};

export default OurService;
