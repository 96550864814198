import AboutUsDetails from "../components/aboutus/AboutUsDetails";

import React, { useEffect } from "react";

const AboutUs = () => {
  

 
  return (
   
      <AboutUsDetails />
  
  );
};

export default AboutUs;
