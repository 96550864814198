
import CoursesList from "../components/ourcourses/CoursesList";

const OurCourses = () => {

    
    return (
        <>
            <CoursesList />
        </>
    );
};

export default OurCourses;
