import Address from "../components/contactus/Address";
import MainTainenceHero from "../components/maintainence/MainTainenceHero";
import React from "react";

const WebMaintainence = () => {
  
  return (
   <>
      <MainTainenceHero />
      <Address />
      </>
  
  );
};

export default WebMaintainence;
